import React from 'react';
import BannerTitle from '../../components/BannerTitle';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import {
	Container,
	Main
} from './styles';

const SchoolSupplies: React.FC = () => {
	const url = process.env.REACT_APP_URL_FILES + "/lista-material-2022"

	return (
		<Container>
			<Header />

			<BannerTitle titlePage={'Lista de material escolar - 2022'} />

			<Main>
				<dl>
					<dt><h2>Educação Infantil</h2></dt>
					<dd><a href={`${url}/jardim_1.pdf`} rel="noopener noreferrer" target="_blank">Jardim I</a></dd>
					<dd><a href={`${url}/jardim_2.pdf`} rel="noopener noreferrer" target="_blank">Jardim II</a></dd>
					<dt><h2>Ensino Fundamental I</h2></dt>
					<dd><a href={`${url}/1_ano.pdf`} rel="noopener noreferrer" target="_blank">1º ANO</a></dd>
					<dd><a href={`${url}/2_ano.pdf`} rel="noopener noreferrer" target="_blank">2º ANO</a></dd>
					<dd><a href={`${url}/3_ano.pdf`} rel="noopener noreferrer" target="_blank">3º ANO</a></dd>
					<dd><a href={`${url}/4_ano.pdf`} rel="noopener noreferrer" target="_blank">4º ANO</a></dd>
					<dd><a href={`${url}/5_ano.pdf`}rel="noopener noreferrer" target="_blank">5º ANO</a></dd>
					<dt><h2>Ensino Fundamental II</h2></dt>
					<dd><a href={`${url}/6_ano.pdf`} rel="noopener noreferrer" target="_blank">6º ANO</a></dd>
					<dd><a href={`${url}/7_ano.pdf`} rel="noopener noreferrer" target="_blank">7º ANO</a></dd>
					<dd><a href={`${url}/8_ano.pdf`} rel="noopener noreferrer" target="_blank">8º ANO</a></dd>
					<dd><a href={`${url}/9_ano.pdf`} rel="noopener noreferrer" target="_blank">9º ANO</a></dd>
				</dl>
			</Main>

			<Footer />
		</Container>
	);
}

export default SchoolSupplies;