import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '../pages/Home';
import SchoolSupplies from '../pages/SchoolSupplies';
import PageWithSideMenu from '../pages/PageWithSideMenu';
import GalleriesPhotos from '../pages/GalleriesPhotos';

const Routes: React.FC = () => (
	<Switch>
		<Route path="/" exact component={Home} />

		<Route path="/fale-conosco" render={(props) => <Home {...props} contactUs />} />

		<Route
			path="/escola/:slug/fotos/:gallery"
			render={(props) => <PageWithSideMenu {...props} titlePage="Escola" mainSlug="escola" hasGallery />}
		/>
		<Route
			path="/escola/:slug"
			render={(props) => <PageWithSideMenu {...props} titlePage="Escola" mainSlug="escola" />}
		/>

		<Route
			path="/proposta-pedagogica/:slug"
			render={(props) => <PageWithSideMenu {...props} titlePage="Proposta Pedagógica" mainSlug="proposta-pedagogica" />}
		/>

		<Route
			path="/galeria/fotos/turmas/:year"
			exact
			render={(props) => <GalleriesPhotos {...props} mainGallery="classes" hasSubGalleries />}
		/>

		<Route
			path="/galeria/fotos/turmas/:year/:album"
			exact
			render={(props) => <GalleriesPhotos {...props} mainGallery="classes" />}
		/>

		<Route
			path="/galeria/fotos/ambientes/:album"
			exact
			render={(props) => <GalleriesPhotos {...props} mainGallery="units" />}
		/>

		{/* <Route path="/galeria/fotos/turmas" exact component={GalleriesPhotos} /> */}
		<Route path="/lista-material" component={SchoolSupplies} />
		<Route path="*" component={Home} />
	</Switch>
);

export default Routes;
