import styled from 'styled-components';

import svgBackground from '../../assets/home/Mobile.svg';

export const Container = styled.div`
	width: 100%;
	height: 170px;
	background-color: #0f2a9a;
	background-image: url(${svgBackground});
	background-size: 700px;
	color: #fff;

	display: flex;
	align-items: center;
	justify-content: center;

	h1 {
		font-size: 2.4rem;
		text-transform: uppercase;
		text-shadow: 3px 3px rgba(0, 0, 0, 0.6);
		text-align: center;
	}

	@media screen and (max-width: 670px) {
		height: 150px;
	}
`;
