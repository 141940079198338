import styled from 'styled-components';
import { opacify } from 'polished';

export const Container = styled.div`
	width: 100vw;
	background-color: #fff;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);

	position: sticky;
	top: -39px;
	z-index: 9;

	@media screen and (max-width: 500px) {
		top: 0px;
		background-color: rgba(255, 255, 255, 0.98);
	}
`;

export const CostumerService = styled.div`
	width: 100vw;
	height: 38px;
	background-color: ${opacify(1, '#FF7F00')};

	display: flex;
	align-items: flex-end;

	@media screen and (max-width: 500px) {
		display: none;
	}
`;

export const ContainerCostumerService = styled.div`
	width: 100%;
	color: #fff;

	padding: 0 30px;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	a {
		color: #fff;

		&:hover {
			color: #0f2a9a;
		}
	}

	#phones {
		display: flex;
		align-items: center;

		svg {
			color: #fff;
		}

		span {
			color: #fff;
			margin-left: 10px;
		}
	}

	#mediaSocial {
		svg {
			margin-left: 15px;
		}
	}

	.barPhones {
		color: #ff7f00;
	}
`;

export const MainMenu = styled.div`
	width: 100vw;
	height: 100px;

	display: flex;
	align-items: center;
	justify-content: center;

	@media (max-width: 850px) {
		height: 85px;
	}

	@media (max-width: 468px) {
		height: 75px;
	}
`;

export const ContainerMainMenu = styled.div`
	width: 100%;
	max-width: 950px;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	img {
		width: 300px;

		@media (max-width: 600px) {
			width: 200px;
		}

		@media (max-width: 320px) {
			width: 150px;
		}
	}
	nav .active {
		color: #ff7f00;
	}

	a {
		color: #0f2a9a;
		text-decoration: none;
		margin-left: 20px;
		font-size: 17px;
	}

	.menu-section {
		margin-right: 30px;
	}

	.menu-toggle {
		display: none;
	}

	a:hover {
		color: #ff7f00;
	}

	@media screen and (max-width: 850px) {
		nav {
			display: none;
		}

		.one,
		.two,
		.three {
			background: #ff7f00;
			height: 4px;
			width: 100%;
			margin: 6px auto;

			transition-duration: 0.3s;
		}

		.one {
			margin-top: 0px;
		}

		.menu-toggle {
			width: 40px;
			height: 30px;
			margin-right: 40px;
			display: block;
			border: none;
			background-color: transparent;

			@media (max-width: 400px) {
				margin-right: 20px;
			}
		}

		.menu-section.on {
			position: absolute;
			top: 0;
			left: 0;

			width: 100vw;
			height: 100vh;
			z-index: 10;
			transition-duration: 0.3s;

			background: #0f2a9a;

			display: flex;
			align-items: center;
			justify-content: center;
		}

		.menu-section {
			margin-right: 0px;
		}

		.menu-section.on nav {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-around;

			a {
				display: block;
				color: #fff;
				text-transform: uppercase;
				transition-duration: 0.5s;
				font-size: 1.5rem;
				line-height: 3.6rem;
			}
		}

		.menu-section.on .menu-toggle {
			position: absolute;
			right: 0px;
			top: 30px;
		}

		.menu-section.on .menu-toggle .one {
			transform: rotate(45deg) translate(5px, 7px);
		}

		.menu-section.on .menu-toggle .two {
			opacity: 0;
		}

		.menu-section.on .menu-toggle .three {
			transform: rotate(-45deg) translate(7px, -9px);
		}
	}
`;
