import styled from 'styled-components';
import { shade } from 'polished';

import svgBackground from '../../assets/home/Mobile.svg';

export const Container = styled.div`
	width: 100vw;

	display: flex;
	align-items: center;
	flex-direction: column;

	.logoWhatsapp {
		position: fixed;
		right: 48px;
		bottom: 140px;
		z-index: 10;

		&:hover img {
			width: 70px;
		}

		img {
			width: 64px;
		}
	}

	.logoSchoolsChannel {
		position: fixed;
		right: 20px;
		bottom: 10px;
		z-index: 10;

		&:hover img {
			width: 144px;
		}

		img {
			width: 124px;
		}
	}

	@media screen and (max-width: 767px) {
		.logoWhatsapp {
			right: 20px;
			bottom: 10px;
		}

		.logoSchoolsChannel {
			right: 98px;
			bottom: 0px;

			img {
				width: 98px;
			}
		}
	}

`;

export const MainBannerContainer = styled.div`
	width: 100%;
	background-color: #0f2a9a;
	background-image: url(${svgBackground});
	padding: 50px 0 0 0;

	display: flex;
	align-items: center;
	flex-direction: column;

	@media screen and (max-width: 800px) {
		height: 550px;
	}

	@media screen and (max-width: 630px) {
		height: 420px;
	}

	@media screen and (max-width: 428px) {
		height: 320px;
	}

	.responsiveContainer {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.containerSlider {
		width: 100%;
		max-width: 943px;
		height: 412px;

		@media screen and (max-width: 800px) {
			height: 290px;
		}

		@media screen and (max-width: 630px) {
			height: 220px;
		}

		@media screen and (max-width: 500px) {
			height: 190px;
		}

		@media screen and (max-width: 428px) {
			height: 130px;
		}

		.itemCarousel {
			width: 100%;

			img {
				width: 100%;
			}
		}

		.slick-dots li button:before {
			font-size: 10px;
			line-height: 20px;
			position: absolute;
			top: -34px;
			left: 0;
			width: 20px;
			height: 20px;
			text-align: center;
			opacity: 0.5;
			color: #0f2a9a;
		}

		.slick-dots li.slick-active button:before {
			opacity: 0.75;
			color: #ff7f00;
		}
	}

	.waveButtons {
		padding-top: 50px;
		width: 100%;
		height: 190px;

		@media screen and (max-width: 800px) {
			padding-top: 0px;
			height: 10px;
		}

		.wave {
			height: 250px;
			overflow: hidden;

			@media screen and (max-width: 500px) {
				height: 200px;
			}

			@media screen and (max-width: 410px) {
				height: 150px;
			}

			svg {
				height: 100%;
				width: 100%;

				path {
					stroke: none;
					fill: #f8f8f8;
				}
			}
		}

		.containerButtons {
			display: flex;
			align-items: center;
			justify-content: center;

			.buttonsLinks {
				width: 85%;
				position: relative;
				top: -240px;

				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;

				a {
					text-decoration: none;
				}

				@media (max-width: 950px) {
					width: 100%;
				}

				@media (max-width: 568px) {
					top: -222px;
				}

				@media (max-width: 428px) {
					top: -219px;
				}

				@media screen and (max-width: 500px) {
					top: -185px;
				}

				@media screen and (max-width: 410px) {
					top: -135px;
				}

				.circleLink {
					width: 160px;
					height: 160px;
					border-radius: 50%;
					background-color: #fff;
					border: 7px solid #fff;

					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					h3 {
						width: 70%;
						text-align: center;
						line-height: 28px;
					}

					svg {
						font-size: 62px;
					}

					@media (max-width: 930px) {
						width: 150px;
						height: 150px;

						h3 {
							font-size: 22px;
							line-height: 24px;
						}

						svg {
							font-size: 42px;
						}
					}

					@media (max-width: 568px) {
						width: 110px;
						height: 110px;
						border: 5px solid #fff;

						h3 {
							font-size: 16px;
							line-height: 18px;
						}
					}

					@media (max-width: 428px) {
						width: 100px;
						height: 100px;
						border: 4px solid #fff;

						h3 {
							font-size: 14px;
							line-height: 16px;
						}

						svg {
							display: none;
						}
					}

					@media (max-width: 399px) {
						width: 80px;
						height: 80px;

						h3 {
							width: 100%;
						}
					}
				}

				.pink {
					border-color: #ff009d;

					h3,
					svg {
						color: #ff009d;
					}

					&:hover {
						background-color: ${shade(0.1, '#fcfcfc')};
						border-color: ${shade(0.25, '#FF009D')};

						h3,
						svg {
							color: ${shade(0.25, '#FF009D')};
						}
					}
				}

				.orange {
					border-color: #ff7f00;

					h3,
					svg {
						color: #ff7f00;
					}

					&:hover {
						background-color: ${shade(0.1, '#fcfcfc')};
						border-color: ${shade(0.25, '#FF7F00')};

						h3,
						svg {
							color: ${shade(0.25, '#FF7F00')};
						}
					}
				}

				.green {
					border-color: #0dad07;

					h3,
					svg {
						color: #0dad07;
					}

					&:hover {
						background-color: ${shade(0.1, '#fcfcfc')};
						border-color: ${shade(0.25, '#0DAD07')};

						h3,
						svg {
							color: ${shade(0.25, '#0DAD07')};
						}
					}
				}

				.purple {
					border-color: #6e33de;

					h3,
					svg {
						color: #6e33de;
					}

					&:hover {
						background-color: ${shade(0.1, '#fcfcfc')};
						border-color: ${shade(0.25, '#6E33DE')};

						h3,
						svg {
							color: ${shade(0.25, '#6E33DE')};
						}
					}

					/* @media (max-width: 767px) {
            display: none;
          } */
				}
			}
		}

		@media (max-width: 568px) {
			padding-top: 10px;
		}

		@media (max-width: 399px) {
			padding-top: -20px;
		}
	}
`;

export const AboutUsContainer = styled.div`
	width: 100%;
	margin: 110px 0 50px;
	background-color: #f8f8f8;

	display: flex;
	align-items: center;
	flex-direction: column;

	@media (max-width: 550px) {
		margin-top: 50px;
	}

	.titleBlock {
		width: 100%;
		padding-bottom: 50px;

		display: flex;
		place-content: center;

		h2 {
			width: 200px;
			padding: 15px;
			color: #0f2a9a;
			text-align: center;
			border-bottom: thick solid #e58e2e;
		}
	}

	.contentAboutUs {
		width: 100%;
		color: #0f2a9a;

		display: flex;
		flex-direction: column;
		align-items: center;

		p {
			width: 84%;
			font-size: 18px;
			text-align: justify;
			line-height: 28px;
			padding-bottom: 15px;

			@media (max-width: 550px) {
				font-size: 16px;
				line-height: 26px;
			}
		}

		a {
			padding: 10px 40px;
			text-decoration: none;
			color: #0f2a9a;
			border: 2px solid #0f2a9a;
			border-radius: 8px;
			margin-top: 45px;
		}

		a:hover {
			color: #fff;
			background: #0f2a9a;
			border: 2px solid #0f2a9a;
		}

		.imgAboutUs {
			flex: 1;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 76%;
			}
		}
	}
`;

export const PedagogicalProposalContainer = styled.div`
	width: 100%;
	padding-bottom: 10px;
	background-color: #fc9936;
	background-image: url(${svgBackground});
	background-size: 950px;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	.wave {
		width: 100%;
		height: 250px;
		overflow: hidden;
		position: relative;
		top: -10px;

		svg {
			height: 100%;
			width: 100%;

			path {
				stroke: none;
				fill: #f8f8f8;
			}
		}
	}

	.waveBottom {
		width: 100%;
		height: 250px;
		overflow: hidden;
		position: relative;
		bottom: -20px;

		svg {
			height: 100%;
			width: 100%;

			path {
				stroke: none;
				fill: #f8f8f8;
			}
		}
	}

	.titleBlock {
		width: 100%;
		padding-bottom: 50px;

		display: flex;
		place-content: center;

		h2 {
			font-size: 30px;
			text-align: center;
			padding-bottom: 15px;
			margin-bottom: 15px;
			color: #0f2a9a;
			border-bottom: thick solid #ffffff;

			@media (max-width: 768px) {
				font-size: 26px;
			}

			@media (max-width: 600px) {
				font-size: 24px;
			}
		}

		p {
			font-size: 14px;
		}
	}
	.containerProposal {
		width: 100%;
		margin-bottom: 20px;
		overflow: hidden;

		display: flex;
		align-items: center;
		justify-content: space-between;

		a {
			text-decoration: none;
		}

		.containerSegmento {
			width: 270px;
			height: 420px;
			padding: 20px 10px 10px;
			border-radius: 18px;

			display: flex;
			flex-direction: column;
			align-items: center;

			&:hover img {
				background-color: #dde4fd;
				margin-top: -20px;
				box-shadow: 0px 7px 10px #0f2a9a;
			}

			&:hover div {
				margin-top: 30px;
				background-color: #dde4fd;
				box-shadow: 0px 7px 6px #0f2a9a;
			}

			img {
				border: 5px solid #f3eded;
				border-radius: 50%;
				box-shadow: 0px 3px 10px #5e5e5e;
			}

			div {
				height: 178px;
				margin-top: 20px;
				padding: 10px;
				text-align: center;
				color: #0f2a9a;
				background: #f3eded;
				border-radius: 8px;
				box-shadow: 0px 3px 10px #747272;

				display: flex;
				flex-direction: column;
				align-items: center;

				h3 {
					color: #0f2a9a;
					margin-bottom: 10px;
				}
			}

			@media (max-width: 856px) {
				width: 230px;
				height: auto;

				div {
					height: 200px;
				}
			}
		}

		@media (max-width: 767px) {
			flex-direction: column;
			align-items: flex-start;

			.containerSegmento {
				flex-direction: row;
				/* justify-content: center; */
				width: 100%;

				div {
					width: auto;
					height: auto;
					min-height: 150px;
					margin-top: 0;
					margin-left: 30px;
					flex: 1;
				}

				&:hover img {
					margin-top: 0;
				}

				&:hover div {
					margin-top: 0;
					margin-left: 20px;
				}
			}
		}

		@media (max-width: 499px) {
			img {
				width: 35%;
			}
		}

		@media (max-width: 399px) {
			.containerSegmento {
				flex-direction: column;
				margin-bottom: 10px;

				div {
					margin-left: 0;
				}

				img {
					width: 50%;
					margin-bottom: 10px;
				}

				&:hover div {
					margin-left: 0;
				}
			}
		}
	}
`;

export const NewsContainer = styled.div`
	width: 100%;
	padding: 0 0 30px 0;
	background: #f8f8f8;

	display: flex;
	align-items: center;
	flex-direction: column;

	.titleBlock {
		width: 100%;
		padding-bottom: 50px;

		display: flex;
		place-content: center;

		h2 {
			padding: 10px;
			color: #0f2a9a;
			text-align: center;
			border-bottom: thick solid #ff7f00;
		}
	}

	.seeAll {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		a {
			text-decoration: none;
			font-style: italic;
			color: #ff7f00;

			display: flex;
			align-items: center;

			svg {
				margin-left: 8px;
			}
		}

		a:hover {
			color: #0f2a9a;
		}
	}

	.listNews {
		display: flex;
		justify-content: space-between;
		margin-top: 8px;

		.publication {
			width: 270px;
			height: 318px;
			padding: 0 0 15px 0;
			border-radius: 10px;
			border-bottom: 5px solid #0f2a9a;
			background: #fff;

			img {
				width: 270px;
				border-radius: 10px 10px 0 0;
			}

			h3 {
				margin-top: 15px;
				padding: 0 15px;
				font-size: 18px;
				line-height: 24px;
			}

			.publDate {
				color: #ff7f00;
				margin-top: 38px;
				padding: 0 15px;

				display: flex;
				align-items: center;

				svg {
					margin-right: 10px;
				}
			}
		}
	}
`;

export const GalleriesContainer = styled.div`
	width: 100%;
	padding: 100px 0 0px;
	background: #0f2a9a;

	display: flex;
	align-items: center;
	flex-direction: column;

	.titleBlock {
		width: 100%;
		padding-bottom: 50px;

		display: flex;
		place-content: center;

		h2 {
			padding: 10px;
			color: #fff;
			text-align: center;
			border-bottom: thick solid #ff7f00;
		}
	}

	.seeAll {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		a {
			text-decoration: none;
			font-style: italic;
			color: #fff;

			display: flex;
			align-items: center;

			svg {
				margin-left: 8px;
			}
		}

		a:hover {
			color: #ff7f00;
		}
	}

	.picsGallery,
	.videosGallery {
		margin: 8px 0 80px;

		display: flex;
		align-items: center;
		justify-content: space-between;

		img {
			width: 300px;
		}
	}
`;

export const ContactUsContainer = styled.div`
	width: 100%;
	padding: 0 0 65px;
	background: #f8f8f8;

	display: flex;
	align-items: center;
	flex-direction: column;

	.titleBlock {
		width: 100%;
		padding-bottom: 40px;

		display: flex;
		place-content: center;

		h2 {
			width: 230px;
			padding: 10px;
			color: #0f2a9a;
			text-align: center;
			border-bottom: thick solid #ff7f00;
		}
	}

	form {
		width: 100%;
		padding: 10px 10%;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	button {
		width: 250px;
		height: 50px;
		margin-top: 35px;
		background: #ff7f00;
		border: none;
		color: #fff;
		border-radius: 24px;

		&:hover {
			background: ${shade(0.1, '#FF7F00')};
		}
	}
`;

export const PartnerLinksContainer = styled.div`
	width: 100%;
	padding: 50px;
	background: #e3e3e3;

	display: flex;
	justify-content: center;

	.titleBlock {
		width: 100%;
		padding-bottom: 40px;

		display: flex;
		place-content: center;

		h2 {
			width: 290px;
			padding: 10px;
			color: #0f2a9a;
			text-align: center;
			border-bottom: thick solid #bfbfbf;
		}
	}

	#img-links {
		width: 100%;
		padding: 0;
		max-height: 100px;

		// background-color: red;

		display: flex;
		align-items: center;
		justify-content: space-evenly;
	}

	img {
		height: 100%;
		max-height: 100px;
		opacity: 0.6;
	}

	img:hover {
		opacity: 1;
		transform: scale(1.2);
	}

	@media screen and (max-width: 768px) {
		#img-links {
			padding: 0;
			justify-content: space-between;
		}

		h2 {
			width: 290px;
		}

		img {
			max-height: 63px;
		}
	}

	@media screen and (max-width: 500px) {
		img {
			max-height: 53px;
		}
	}

	@media screen and (max-width: 400px) {
		img {
			max-height: 43px;
		}
	}
`;
