import React from 'react';
import {
	RiMapPinLine,
	RiPhoneLine,
	RiWhatsappLine,
	RiMailLine,
	RiTimeLine,
	RiFacebookBoxLine,
	RiInstagramLine,
} from 'react-icons/ri';

import { Container, FooterInfo, FooterCopyright } from './styles';

const Footer: React.FC = () => (
	<Container>
		<div className="responsiveContainer">
			<FooterInfo>
				<div id="locate" className="infoBlock">
					<h3>Localização</h3>

					<a
						href="https://www.google.com.br/maps/place/Col%C3%A9gio+Encanto+Juvenil/@-23.6989032,-46.7751018,17z/data=!3m1!4b1!4m5!3m4!1s0x94ce5266391a85d1:0xfea6e1aa0be32ccd!8m2!3d-23.6989081!4d-46.7729078"
						target="_blank"
						rel="noopener noreferrer"
					>
						<RiMapPinLine size={14} />
						R. Agamenon Pereira da Silva, 347 / 965
					</a>

					<p>
						<RiPhoneLine size={14} />
						(011) 5831-1753 / 5835-3928
					</p>

					<a
						href="https://api.whatsapp.com/send?phone=5511968390388&text=&source=&data=&app_absent="
						target="_blank"
						rel="noopener noreferrer"
					>
						<RiWhatsappLine size={14} />
						(011) 9 6839-0388
					</a>

					<p>
						<RiMailLine size={14} />
						atendimento@colegioencantojuvenil.com.br
					</p>
				</div>

				<div id="footerInfoRight">
					<div id="customerService " className="infoBlock">
						<h3>Atendimento</h3>

						<p>
							<RiTimeLine size={14} />
							Seg a Sex - 08h às 18h
						</p>
					</div>

					<div id="socialMedia" className="infoBlock">
						<h3>Sigam-nos</h3>
						<p>
							<a href="https://www.facebook.com/colegioencantojuvenil" target="_blank" rel="noopener noreferrer">
								<RiFacebookBoxLine size={32} />
							</a>

							<a href="https://www.instagram.com/colegioencantojuvenil/" target="_blank" rel="noopener noreferrer">
								<RiInstagramLine size={32} />
							</a>
						</p>
					</div>
				</div>
			</FooterInfo>
		</div>

		<FooterCopyright>
			<span>Copyright © Colégio Encanto Juvenil</span>
		</FooterCopyright>
	</Container>
);

export default Footer;
