import styled from 'styled-components';

export const Container = styled.div`
	width: 100vw;
	background-color: #ff7f00;

	display: flex;
	align-items: center;
	flex-direction: column;

	@media screen and (max-width: 679px) {
		.responsiveContainer {
			display: flex;
			justify-content: center;
		}
	}
`;

export const FooterInfo = styled.div`
	width: 100%;
	margin: 35px 0;

	display: flex;
	align-items: stretch;
	justify-content: space-evenly;

	@media screen and (max-width: 679px) {
		margin-bottom: 0;
	}

	.infoBlock {
		color: #fff;

		h3 {
			color: #4618a3;
		}

		p {
			line-height: 28px;
		}

		a {
			font-family: 'Roboto', sans-serif;
			font-size: 16px;
			line-height: 28px;
			text-decoration: none;
			color: #fff;
		}

		svg {
			margin-right: 10px;

			&:hover {
				color: #0f2a9a;
			}
		}
	}

	#footerInfoRight {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	@media screen and (max-width: 679px) {
		flex-direction: column;

		.infoBlock {
			width: 100%;
			margin-bottom: 25px;
		}
	}
`;

export const FooterCopyright = styled.div`
	width: 100vw;
	height: 50px;
	color: #fff;
	background-color: #0f2a9a;

	display: flex;
	align-items: center;
	justify-content: center;
`;
