import styled from 'styled-components';

export const Content = styled.div`
	width: 100%;
	max-width: 1024px;
	margin-top: 66px;
	padding-bottom: 100px;

	display: flex;
	flex-direction: row;
`;

export const SecundaryMenu = styled.aside`
	width: 44%;
	margin-left: 10px;

	div {
		position: sticky;
		top: 130px;
		font-size: 20px;
		line-height: 32px;
		list-style: none;
		margin-bottom: 20px;

		display: flex;
		flex-direction: column;

		a {
			padding-left: 15px;
			text-decoration: none;
			color: #0f2a9a;

			@media screen and (max-width: 750px) {
				font-size: 16px;
				margin-bottom: 10px;
			}
		}

		.selectedMenu {
			padding-left: 10px;
			border-left: 5px solid #ff7f00;
			color: #ff7f00;
			font-weight: bold;
		}
	}

	@media screen and (max-width: 630px) {
		/* display: none; */
	}
`;

export const ContentSelected = styled.main`
	width: 100%;
	min-height: 300px;
	margin-left: 16px;
	margin-right: 25px;

	h2 {
		color: #ff7f00;
		margin-bottom: 20px;
	}

	h3 {
		margin: 44px 0 14px 0;
	}

	p {
		margin-bottom: 16px;
	}

	a {
		text-decoration: none;
		color: #0f2a9a;
	}

	a:hover {
		color: #ff7f00;
	}
`;
