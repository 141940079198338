import React, {useCallback, useEffect, useState} from 'react';
import { Link } from 'react-router-dom'
import { MdLaptopChromebook, MdSchool, MdImportContacts } from "react-icons/md";
import Slider from 'react-slick';
import { Form } from '@unform/web';
import ScrollableAnchor, { configureAnchors, goToAnchor, goToTop } from 'react-scrollable-anchor';
import ReactHtmlParser from 'react-html-parser';

import { GApageView } from '../../services/analytics';
import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Input from '../../components/Form/Input';
import TextArea from '../../components/Form/TextArea';
import Select from '../../components/Form/Select';

import whatsapp from '../../assets/home/whatsapp.svg';
import schoolChannel from '../../assets/home/web_canal_escola.png';
import imgEdInf from '../../assets/home/pedagogicalProposal/ed-infantil.png';
import imgEnsFundI from '../../assets/home/pedagogicalProposal/ens-fund-i.png';
import imgEnsFundII from '../../assets/home/pedagogicalProposal/ens-fund-ii.png';
import angloImg from '../../assets/home/partnerLinks/anglo.png';
import plurallImg from '../../assets/home/partnerLinks/plurall.png';
import arvoreImg from '../../assets/home/partnerLinks/arvore-livro.png'
import liderEmMimImg from '../../assets/home/partnerLinks/o-lider-em-mim.png';
import red1000 from '../../assets/home/partnerLinks/redacao_nota_1000.jpg';
import geppetto from '../../assets/home/partnerLinks/geppetto.png';

import {
	Container,
	MainBannerContainer,
	AboutUsContainer,
	PedagogicalProposalContainer,
	ContactUsContainer,
	PartnerLinksContainer
} from './styles';

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css";

interface IContentsProps {
	name: string;
	description: string;
	slug: string;
}

interface IBannersProps {
	name: string;
	url_image: string;
	redirect_link: string;
}

interface IContactUsDataProps {
	name: string;
	email: string;
	phone: string;
	subject: string;
	message: string;
}

interface IHomeProps {
	contactUs?: boolean
}

const Home: React.FC<IHomeProps> = ({contactUs = false}) => {
	const [contents, setContents] = useState<IContentsProps[]>([]);
	const [banners, setBanners] = useState<IBannersProps[]>([]);

	const aboutUs = contents.find( content => content.slug === 'sobre-nos');
	const edInfantil = contents.find( content => content.slug === 'educacao-infantil');
	const ensFundI = contents.find( content => content.slug === 'ensino-fundamental-I');
	const ensFundII = contents.find( content => content.slug === 'ensino-fundamental-II');

	const options = [
		{ value: 'atendimento', label: 'Atendimento' },
		{ value: 'agendamento-visita', label: 'Agende sua Visita' },
		{ value: 'matricula', label: 'Matrícula' },
		{ value: 'trabalhe-conosco', label: 'Trabalhe Conosco' }
	]

	const handleSubmit = useCallback(
		async (data: IContactUsDataProps, { reset }) => {
			await api.post('contacts', {
				name: data.name,
				email: data.email,
				phone: data.phone,
				subject: data.subject,
				message: data.message,
				segment: "teste"
			})

		reset();
	}, []);

	configureAnchors({offset: -110, scrollDuration: 400})

	useEffect(() => {
		async function loadContents() {
			await api.get('contents').then(response => {
				setContents(response.data.home);
			});
		}

		async function loadBanners() {
			await api.get('banners').then(response => {
				setBanners(response.data);
			});
		}

		loadContents();
		loadBanners();

		GApageView('/');
	}, [])

	useEffect(() => {
		contactUs ? goToAnchor('fale-conosco', false) : goToTop();
	}, [contactUs])

	return (
		<Container>

			<Header />

			<a
				className="logoSchoolsChannel"
				href="https://webcanaldaescola.com.br/102"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img src={schoolChannel} alt="Web Canal da Escola" />
			</a>

			<a
				className="logoWhatsapp"
				href="https://api.whatsapp.com/send?phone=5511968390388"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img src={whatsapp} alt="Whatsapp" />
			</a>

			<MainBannerContainer>
				<div className="responsiveContainer">
					<div className="containerSlider" >
						<Slider
							dots
							autoplay
							autoplaySpeed = {3000}
							speed = {500}
							arrows = {false}
							infinite
						>
							{
								banners.map(banner => (
									<div className="itemCarousel" key={banner.url_image}>
										{
											banner.redirect_link ? (
												<a href={banner.redirect_link}>
													<img src={banner.url_image} alt={banner.name} />
												</a>
											) : (
												<img src={banner.url_image} alt={banner.name} />
											)
										}
									</div>
								))
							}

						</Slider>
					</div>
				</div>

				<div className="waveButtons">
					<div className="wave">
						<svg viewBox="0 0 500 150" preserveAspectRatio="none">
							<path d="M-9.59,-5.42 C181.71,178.13 281.04,-42.92 509.59,76.47 L500.00,150.00 L0.00,150.00 Z"></path>
						</svg>
					</div>

					<div className="containerButtons">
						<div className="responsiveContainer">
							<div className="buttonsLinks">
								<a
									href="http://tesisescolar.tesis.inf.br"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="circleLink pink">
										<MdLaptopChromebook />
										<h3>Agenda Digital</h3>
									</div>
								</a>

								<Link to="/fale-conosco">
									<div className="circleLink orange">
										<MdSchool />
										<h3>Matrícula</h3>
									</div>
								</Link>

								<Link to="/fale-conosco">
									<div className="circleLink purple">
										<MdImportContacts />
										<h3>Fale Conosco</h3>
									</div>
								</Link>

							</div>
						</div>
					</div>
				</div>

			</MainBannerContainer>

			<AboutUsContainer id="about-us">
				<div className="responsiveContainer">
					<div className="titleBlock">
						<h2>{aboutUs?.name.toUpperCase()}</h2>
					</div>

					<div className="contentAboutUs" >
						{ReactHtmlParser(aboutUs ? aboutUs.description : '')}

						<Link to="/escola/historia">Saiba mais ...</Link>
					</div>
				</div>
			</AboutUsContainer>

			<PedagogicalProposalContainer id="pedag-proposal">
				<div className="wave">
					<svg viewBox="0 0 500 150" preserveAspectRatio="none">
						<path d="M0.00,49.98 C149.99,150.00 271.49,-49.98 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"></path>
					</svg>
				</div>

				<div className="responsiveContainer">
					<div className="titleBlock">
						<h2>PROPOSTA PEDAGÓGICA</h2>
					</div>

					<div className="containerProposal">
						<Link to="/proposta-pedagogica/educacao-infantil">
							<div className="containerSegmento">
								<img src={imgEdInf} alt="Educação Infantil" />

								<div>
									<h3>{edInfantil?.name}</h3>

									{ReactHtmlParser(edInfantil ? edInfantil.description : '')}
								</div>
							</div>
						</Link>

						<Link to="/proposta-pedagogica/ensino-fundamental-I">
							<div className="containerSegmento">
								<img src={imgEnsFundI} alt="Ensino Fundamental I" />

								<div>
									<h3>{ensFundI?.name}</h3>

									{ReactHtmlParser(ensFundI ? ensFundI.description : '')}
								</div>
							</div>
						</Link>

						<Link to="/proposta-pedagogica/ensino-fundamental-II">
							<div className="containerSegmento">
								<img src={imgEnsFundII} alt="Ensino Fundamental II" />

								<div>
									<h3>{ensFundII?.name}</h3>

									{ReactHtmlParser(ensFundII ? ensFundII.description : '')}
								</div>
							</div>
						</Link>
					</div>
				</div>

				<div className="waveBottom">
					<svg viewBox="0 0 500 150" preserveAspectRatio="none">
						<path d="M0.00,49.98 C149.99,150.00 349.20,-49.98 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"></path>
					</svg>
				</div>
			</PedagogicalProposalContainer>

			<ContactUsContainer id="contact-us">
				<ScrollableAnchor id={'fale-conosco'}>
					<div className="responsiveContainer">
						<div className="titleBlock">
							<h2>FALE CONOSCO</h2>
						</div>

						<Form onSubmit={handleSubmit}>
							<Input name="name" placeholder="Nome do Responsável" required/>
							<Input name="email" type="email" placeholder="E-mail" />
							<Input name="phone" type="text" placeholder="Telefone" required/>
							<Select name="subject" type="text" placeholder="Assunto" options={options} required />
							<TextArea name="message" placeholder="Mensagem" required/>

							<button type="submit">Enviar</button>
						</Form>
					</div>
				</ScrollableAnchor>
			</ContactUsContainer>

			<PartnerLinksContainer id="partner-links">
				<div className="responsiveContainer">
					<div className="titleBlock">
						<h2>LINKS PARCEIROS</h2>
					</div>

					<div id="img-links">
						<a
							href="http://www.sistemaanglo.com.br/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src={angloImg} alt="Sistema Anglo"/>
						</a>

						<a
							href="https://www.plurall.net/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src={plurallImg} alt="Plurall"/>
						</a>

						<a
							href="http://www.olideremmim.com.br/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src={liderEmMimImg} alt="Lider em Mim"/>
						</a>

						<a
							href="https://www2.arvoredelivros.com.br/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src={arvoreImg} alt="Árvore de Livros"/>
						</a>

						<a
							href="https://www.geppetto.com.br/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src={geppetto} alt="Geppetto"/>
						</a>

						<a
							href="https://blog.redacaonota1000.com.br/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src={red1000} alt="Redação Nota 1000"/>
						</a>
					</div>
				</div>
			</PartnerLinksContainer>

			<Footer />

		</Container>
	);
}

export default Home;