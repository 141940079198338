import React, { useState, useCallback } from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';

import { Container } from './styles';

interface IPhotoProps {
	src: string;
	width: number;
	height: number;
}

interface ICurrentGallery {
	event: string;
	path: string;
	images: string[];
	slug: string;
}

interface ICurrentGalleryParamsProps {
	currentGallery: ICurrentGallery;
}

const CurrentGallery: React.FC<ICurrentGalleryParamsProps> = ({ currentGallery }) => {
	const [currentImage, setCurrentImage] = useState(0);
	const [viewerIsOpen, setViewerIsOpen] = useState(false);

	const openLightbox = useCallback((event, { photo, index }) => {
		setCurrentImage(index);
		setViewerIsOpen(true);
	}, []);

	const closeLightbox = () => {
		setCurrentImage(0);
		setViewerIsOpen(false);
	};

	const photos: IPhotoProps[] | undefined =
		currentGallery &&
		currentGallery.images.map((image) => ({
			src: `https://img.colegioencantojuvenil.com.br${currentGallery.path}/${image}`,
			width: 4,
			height: 3,
		}));

	return (
		<Container>
			<h2>{currentGallery.event}</h2>

			<Gallery photos={photos} columns={4} onClick={openLightbox} />

			<ModalGateway>
				{viewerIsOpen ? (
					<Modal onClose={closeLightbox}>
						<Carousel
							currentIndex={currentImage}
							views={photos.map((x) => ({
								...x,
								source: x.src,
								caption: x.width,
							}))}
						/>
					</Modal>
				) : null}
			</ModalGateway>
		</Container>
	);
};

export default CurrentGallery;
