import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;

	display: flex;
	align-items: center;
	flex-direction: column;
`;

export const GalleriesContainer = styled.div`
	width: 100%;
	margin-top: 66px;
	padding-bottom: 100px;

	display: flex;
	flex-direction: row;
`;

export const AsideMenu = styled.aside`
	width: 200px;
	margin-left: 20px;

	.albums {
		position: sticky;
		top: 130px;
		font-size: 20px;
		line-height: 32px;
		list-style: none;
		margin-bottom: 20px;

		display: flex;
		flex-direction: column;
	}
`;

export const Album = styled.li`
	font-weight: bold;

	& + li {
		margin-top: 15px;
	}

	ul {
		list-style: none;
	}

	a {
		padding-left: 15px;
		text-decoration: none;
		color: #0f2a9a;
		font-size: 18px;
		font-weight: normal;
		font-style: italic;

		&:hover {
			color: #ff7f00;
		}

		@media screen and (max-width: 750px) {
			font-size: 16px;
			margin-bottom: 10px;
		}
	}

	.selectedMenu {
		padding-left: 10px;
		border-left: 5px solid #ff7f00;
		color: #ff7f00;
		font-weight: bold;
	}
`;

export const ActiveMediaGallery = styled.main`
	width: calc(100% - 200px);

	min-height: 300px;
	margin-left: 16px;
	margin-right: 25px;

	h2 {
		color: #ff7f00;
		margin-bottom: 20px;
		text-align: center;
	}

	h3 {
		margin: 44px 0 14px 0;
	}

	p {
		margin-bottom: 16px;
	}
`;
