import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		outline: 0;
	}

	body {
		background: #f8f8f8;
		color: #0F2A9A;
		-webkit-font-smoothing: antialiased;
		overflow-x: hidden;
	}

	body, input, button, textarea, select {
		font-family: 'Roboto', sans-serif;
		font-size: 16px;
		line-height: 20px;
	}

	h1 {
		font-family: 'Roboto', sans-serif;
		font-size: 39px;
		line-height: 59px;
	}

	h2 {
		font-family: 'Roboto', sans-serif;
		font-size: 28px;
		line-height: 42px;
	}

	h3 {
		font-family: 'Roboto', sans-serif;
		font-size: 22px;
		line-height: 32px;
	}

	@media screen and (max-width: 679px) {
		h2 {
			font-family: 'Roboto', sans-serif;
			font-size: 24px;
			line-height: 34px;
		}

		h3 {
			font-size: 18px;
			line-height: 28px;
		}

		p, a {
			font-size: 14px;
			line-height: 24px;
		}
	}

	button {
		cursor: pointer;
	}

	.responsiveContainer {
		width: 100%;
		max-width: 950px;

		@media (max-width: 955px) {
			width: 90%;
			max-width: none;
		}
	}
`;
