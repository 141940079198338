import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { Container, Galleries } from './styles';

interface EventsProp {
	event: string;
	path: string;
	images: string[];
	slug: string;
}

interface EventsParamsProp {
	year: string;
}

interface EventsGalleryProp {
	events: EventsProp[];
}

const SubGalleries: React.FC<EventsGalleryProp> = ({ events }) => {
	const { year } = useParams<EventsParamsProp>();

	return (
		<Container>
			{events?.map((gallery) => (
				<Galleries key={gallery.event}>
					<Link to={`/galeria/fotos/turmas/${year}/${gallery.slug}`}>
						<div className="album">
							<div className="img">
								<img
									src={`${process.env.REACT_APP_URL_IMG}${gallery.path}/${gallery.images[0]}`}
									alt={gallery.event}
								/>
							</div>

							<span>{gallery.event}</span>
						</div>
					</Link>
				</Galleries>
			))}
			`
		</Container>
	);
};

export default SubGalleries;
