import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	padding: 16px;
	color: #777777;
	background: #d9ddee;
	border: 2px solid #d9ddee;
	border-radius: 24px;

	display: flex;
	align-items: center;

	& + div {
		margin-top: 11px;
	}

	input {
		flex: 1;
		background: transparent;
		border: 0;
		color: #5c5c5c;
	}
`;
