import React from 'react';

import { initGA } from './services/analytics';

import Routes from './routes';

import GlobalStyle from './styles/global';

const App: React.FC = () => {
	initGA();

	return (
		<>
			<Routes />

			<GlobalStyle />
		</>
	);
};

export default App;
