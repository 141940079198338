import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import { GApageView } from '../../services/analytics';

import { Content, SecundaryMenu, ContentSelected } from './styles';

interface ContentPage {
	name: string;
	description: string;
	slug: string;
	page: string;
}

interface IGalleryProps {
	event: string;
	slug: string;
	path: string;
	images: string[];
}

interface IPhotoProps {
	src: string;
	width: number;
	height: number;
}

interface ContentWithMenuProps {
	mainSlug: string;
	contentsPage: ContentPage[];
}

interface IParamsProp {
	slug: string;
}

const ContentWithMenu: React.FC<ContentWithMenuProps> = ({ mainSlug, contentsPage }) => {
	const [contents, setContents] = useState<ContentPage[]>([]);

	const { slug } = useParams<IParamsProp>();

	const contentPage = contents.find((content) => content.slug === slug);

	useEffect(() => {
		GApageView(`/${contents[0]?.page}/${contents[0]?.slug}`);

		setContents(contentsPage);
	}, [contentsPage, contents]);

	return (
		<Content>
			<SecundaryMenu>
				<div>
					{contents.map((content) => (
						<Link
							key={content.name}
							to={`/${mainSlug}/${content.slug}`}
							className={slug === content.slug ? 'selectedMenu' : 'notSelectedMenu'}
						>
							{content.name}
						</Link>
					))}
				</div>
			</SecundaryMenu>

			<ContentSelected>{ReactHtmlParser(contentPage?.description || '')}</ContentSelected>
		</Content>
	);
};

export default ContentWithMenu;
