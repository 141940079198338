import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import api from '../../services/api';

import Header from '../../components/Header';
import BannerTitle from '../../components/BannerTitle';
import Footer from '../../components/Footer';

import CurrentGallery from '../../screens/CurrentGallery';
import SubGalleries from '../../screens/SubGalleries';

import { Container, GalleriesContainer, AsideMenu, Album, ActiveMediaGallery } from './styles';

interface GalleryProp {
	event: string;
	path: string;
	images: string[];
	slug: string;
}

interface IParamsProp {
	year: string;
	album: string;
}

interface IGalleriesPhotosProp {
	mainGallery: 'classes' | 'units';
	hasSubGalleries?: boolean;
}

const GalleriesPhotos: React.FC<IGalleriesPhotosProp> = ({ mainGallery, hasSubGalleries }) => {
	const [classGalleries, setClassGalleries] = useState([]);
	const [unitsGalleries, setUnitsGalleries] = useState<GalleryProp[]>([]);
	const [subGalleries, setSubGalleries] = useState<GalleryProp[]>([]);
	const [currentGallery, setCurrentGallery] = useState<GalleryProp>();

	const { year, album } = useParams<IParamsProp>();

	const classAtt = {
		years: Object.keys(classGalleries),
		events: Object.values(classGalleries),
	};

	useEffect(() => {
		async function loadClassGalleries() {
			await api.get('galleries_ext').then((response) => {
				setClassGalleries(response.data.photo);
			});
		}

		async function loadUnitsGalleries() {
			await api.get('school_photos_ext').then((response) => {
				setUnitsGalleries(response.data);
			});
		}

		loadClassGalleries();
		loadUnitsGalleries();
	}, []);

	useEffect(() => {
		setSubGalleries(classAtt.events[classAtt.years.findIndex((el) => el === year)]);

		album &&
			setCurrentGallery(
				mainGallery === 'classes'
					? subGalleries?.filter((el) => el.slug === album)[0]
					: unitsGalleries?.filter((el) => el.slug === album)[0],
			);
	}, [mainGallery, album, classAtt, year, subGalleries, unitsGalleries]);

	return (
		<Container>
			<Header />

			<BannerTitle titlePage={'Galeria'} />

			<GalleriesContainer>
				<AsideMenu>
					<ul className="albums">
						<Album>
							AMBIENTES
							<ul>
								{unitsGalleries.map((unit) => (
									<li key={unit.slug}>
										<NavLink to={`/galeria/fotos/ambientes/${unit.slug}`} activeClassName="selectedMenu">
											{unit.event}
										</NavLink>
									</li>
								))}
							</ul>
						</Album>

						<Album>
							TURMAS
							<ul>
								{classAtt.years.map((year) => (
									<li key={year}>
										<NavLink to={`/galeria/fotos/turmas/${year}`} activeClassName="selectedMenu">
											{year}
										</NavLink>
									</li>
								))}
							</ul>
						</Album>
					</ul>
				</AsideMenu>

				<ActiveMediaGallery>
					{hasSubGalleries ? (
						<SubGalleries events={subGalleries} />
					) : (
						currentGallery && <CurrentGallery currentGallery={currentGallery} />
					)}
				</ActiveMediaGallery>
			</GalleriesContainer>

			<Footer />
		</Container>
	);
};

export default GalleriesPhotos;
