import React, { useEffect, useRef, TextareaHTMLAttributes } from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';

import { Container } from './styles';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	name: string;
	icon?: React.ComponentType<IconBaseProps>;
}

const TextArea: React.FC<TextAreaProps> = ({ name, ...rest }) => {
	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	const { fieldName, registerField, defaultValue } = useField(name);

	useEffect(() => {
		registerField({
			name: fieldName,
			ref: textAreaRef.current,
			path: 'value',
		});
	}, [fieldName, registerField]);

	return (
		<Container>
			<textarea defaultValue={defaultValue} ref={textAreaRef} {...rest} />
		</Container>
	);
};

export default TextArea;
