import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import ScrollToTop from './components/ScroollToTop';

import App from './App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') || '';

ReactDOM.render(
	<BrowserRouter basename={baseUrl}>
		<ScrollToTop />
		<App />
	</BrowserRouter>,
	document.getElementById('root'),
);
