import styled from 'styled-components';

export const Container = styled.div`
	.goBackPage {
		font-size: 22px;
		text-decoration: none;
		margin-top: 20px;
		color: #0f2a9a;

		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			margin-right: 10px;
		}

		&:hover {
			color: #ff7f00;
		}
	}
`;
