import React, { useState, useEffect } from 'react';

import api from '../../services/api';

import Header from '../../components/Header';
import BannerTitle from '../../components/BannerTitle';
import ContentWithMenu from '../../components/ContentWithMenu';
import Footer from '../../components/Footer';

import { Container } from './styles';

interface IContentPageProps {
	name: string;
	description: string;
	slug: string;
	page: string;
}

interface IPageWithSideMenuProps {
	titlePage: string;
	mainSlug: 'escola' | 'proposta-pedagogica';
	hasGallery?: boolean;
}

const PageWithSideMenu: React.FC<IPageWithSideMenuProps> = ({ titlePage, mainSlug, hasGallery = false }) => {
	const [contentSchool, setContentSchool] = useState<IContentPageProps[]>([]);
	const [contentPropostaPedagogical, setContentPropostaPedagogical] = useState<IContentPageProps[]>([]);

	let contentPage = [];

	useEffect(() => {
		async function loadContents() {
			await api.get('contents').then((response) => {
				setContentSchool(response.data.escola);
				setContentPropostaPedagogical(response.data.proposta_pedagogica);
			});
		}

		loadContents();
	}, [hasGallery]);

	switch (mainSlug) {
		case 'escola':
			contentPage = contentSchool;
			break;

		case 'proposta-pedagogica':
			contentPage = contentPropostaPedagogical;
			break;
	}

	return (
		<Container>
			<Header />

			<BannerTitle titlePage={titlePage} />

			<ContentWithMenu mainSlug={mainSlug} contentsPage={contentPage} />

			<Footer />
		</Container>
	);
};

export default PageWithSideMenu;
