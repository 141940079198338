import React, { useState, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { RiPhoneLine, RiFacebookBoxLine, RiInstagramLine, RiWhatsappLine } from 'react-icons/ri';

import logoImg from '../../assets/logo.png';

import { Container, CostumerService, ContainerCostumerService, MainMenu, ContainerMainMenu } from './styles';

const Header: React.FC = () => {
	const [showMenuResp, setShowMenuResp] = useState(true);

	const menuSection = document.querySelector('.menu-section');

	const handleMenuResponsive = useCallback(() => {
		document.body.style.overflow = showMenuResp ? 'hidden' : 'initial';

		menuSection?.classList.toggle('on', showMenuResp);
		setShowMenuResp(!showMenuResp);
	}, [menuSection, showMenuResp]);

	const handleCloseMenuResponsive = useCallback(() => {
		document.body.style.overflow = 'initial';
		setShowMenuResp(true);
	}, []);

	return (
		<Container>
			<CostumerService>
				<ContainerCostumerService>
					<div id="phones">
						<RiPhoneLine size={20} />
						<span>5831-1753</span>
						<span className="barPhones"> / </span>
						<span>5835-3928</span>
						<span className="barPhones"> / </span>
						<span>9.6839-0388</span>
					</div>

					<div id="mediaSocial">
						<a
							href="https://api.whatsapp.com/send?phone=5511968390388&text=&source=&data=&app_absent="
							target="_blank"
							rel="noopener noreferrer"
						>
							<RiWhatsappLine size={22} />
						</a>
						<a href="https://www.facebook.com/colegioencantojuvenil" target="_blank" rel="noopener noreferrer">
							<RiFacebookBoxLine size={22} />
						</a>
						<a href="https://www.instagram.com/colegioencantojuvenil/" target="_blank" rel="noopener noreferrer">
							<RiInstagramLine size={22} />
						</a>
					</div>
				</ContainerCostumerService>
			</CostumerService>

			<MainMenu>
				<ContainerMainMenu>
					<NavLink exact to="/">
						<img src={logoImg} alt="Colégio Encanto Juvenil" />
					</NavLink>

					<div className="menu-section">
						<button className="menu-toggle" onClick={handleMenuResponsive}>
							<div className="one"></div>
							<div className="two"></div>
							<div className="three"></div>
						</button>

						<nav>
							<NavLink exact to="/" activeClassName="active" onClick={handleCloseMenuResponsive}>
								Home
							</NavLink>
							<NavLink to="/escola/historia" onClick={handleCloseMenuResponsive} activeClassName="active">
								Escola
							</NavLink>
							<NavLink
								to="/proposta-pedagogica/educacao-infantil"
								onClick={handleCloseMenuResponsive}
								activeClassName="active"
							>
								Proposta Pedagógica
							</NavLink>
							<NavLink
								to="/galeria/fotos/ambientes/unidade-1"
								onClick={handleCloseMenuResponsive}
								activeClassName="active"
							>
								Galeria
							</NavLink>
							<a href="/fale-conosco">Fale Conosco</a>
						</nav>
					</div>
				</ContainerMainMenu>
			</MainMenu>
		</Container>
	);
};

export default Header;
