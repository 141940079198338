import styled from 'styled-components';

export const Container = styled.div`
	width: 100vw;

	display: flex;
	align-items: center;
	flex-direction: column;
`;


export const Main = styled.div`
	margin: 20px;
	min-height: calc(100vh - 612px);

	@media screen and (max-width: 499px) {
		align-self: flex-start;

		dd + dt {
			margin-top: 25px;
		}
	}

	@media screen and (min-width: 500px) {
		dd {
			margin: 5px;
		}
		dl {
			column-count: 3;
			height: 100%;
		}
		dt {
			-webkit-column-break-before: always;
			break-before: column;
		}
	}
`;
