import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;

	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -moz-flex;
	display: -webkit-flex;
	display: flex;

	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;

	@media (max-width: 460px) {
		width: 100%;
	}
`;

export const Galleries = styled.div`
	width: 32%;
	height: 290px;
	padding: 0 5px 20px;
	margin-right: 10px;
	margin-bottom: 40px;

	@media (max-width: 1240px) {
		height: 230px;
	}

	@media (max-width: 1000px) {
		width: 30%;
	}

	@media (max-width: 760px) {
		width: 45%;
		height: 210px;
	}

	@media (max-width: 540px) {
		width: 90%;
		height: 290px;
	}

	display: flex;
	flex-direction: column;
	align-items: center;

	a {
		width: 100%;
		text-decoration: none;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&:hover .album .img {
		border-radius: 8px;
		border: 3px solid #ff7f00;
	}

	.album {
		width: 100%;
		max-width: 300px;
		overflow: hidden;

		display: flex;
		flex-direction: column;
		align-items: center;

		.img {
			width: 300px;
			height: 225px;
			overflow: hidden;
			margin-bottom: 15px;

			display: flex;
			justify-content: center;

			@media (max-width: 1240px) {
				width: 200px;
				height: 150px;
			}

			@media (max-width: 540px) {
				width: 300px;
				height: 225px;
			}

			img {
				height: 100%;
			}
		}
	}

	span {
		font-weight: bold;
		color: #ff7f00;
		text-align: center;
	}
`;
