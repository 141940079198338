import React from 'react';

import { Container } from './styles';

interface BannerTitleProps {
	titlePage: string;
}

const BannerTitle: React.FC<BannerTitleProps> = ({ titlePage }) => {
	return (
		<Container>
			<h1>{titlePage}</h1>
		</Container>
	);
};

export default BannerTitle;
